.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 60px;
  background-color: var(--color-background-light-primary);
  a {
    display: inherit;
  }
  img {
    width: 100%;
    max-width: 105px;
  }

  @media screen and (max-width: 375px) {
    height: 45px;
  }
}
