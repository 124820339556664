/**
 * Do not edit directly
 * Generated on Tue, 19 Jul 2022 19:37:53 GMT
 */

:root {
  --asset-font-mono-bold: "Founders Grotesk Mono Medium";
  --asset-font-mono-normal: "Founders Grotesk Mono Regular";
  --asset-font-tertiary-bold: "Northwell";
  --asset-font-tertiary-normal: "Northwell";
  --asset-font-secondary-bold: "Founders Grotesk Text Medium";
  --asset-font-secondary-normal: "Founders Grotesk Text Regular";
  --asset-font-primary-bold: "Le Jeune Deck Regular";
  --asset-font-primary-normal: "Le Jeune Deck Light";
}
